const initialState = []

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'API:SUBSCRIPTION_TYPES:FETCH:SUCCESS':
      return setPlans(action)
    default:
      return state
  }
}

function setPlans(action) {
  let plans = action.response || action.resp
  return [...plans]
}

export default reducer
