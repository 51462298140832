const initialState = {
  new_measurements_page: 'control'
}

function reducer(state = {}, action) {
  const { response, type } = action
  switch (type) {
    case 'API:EXPERIMENT:GET_EXPERIMENT_RESULT:SUCCESS':
      const { name, variation_name } = response.data.StartExperiment.experiment
      return { ...state, [name]: variation_name }
    default:
      return { ...initialState, ...state }
  }
}

export default reducer
