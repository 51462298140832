const initialState = {
  confirmation: '',
  emails: '',
  emailResponseStatus: [],
  sentEmails: false,
  title: '',
  text: '',
  message: '',
  url: '',
  isSendingEmail: false
}

const fetchProgramSuccess = (state, action) => ({
  ...state,
  confirmation:
    action.response.data.active_rental_referral_program
      .user_facing_confirmation,
  title: action.response.data.active_rental_referral_program.user_facing_title,
  text:
    action.response.data.active_rental_referral_program.user_facing_description,
  message:
    action.response.data.active_rental_referral_program.user_facing_share_intro,
  url: action.response.data.me.referral_url
})

const sendEmailsStarted = state => ({
  ...state,
  isSendingEmail: true
})

const sendEmailsError = state => ({
  ...state,
  isSendingEmail: false
})

const sendEmailsSuccess = (state, action) => ({
  ...state,
  emailResponseStatus:
    action.response.data.SendRentalReferralInvitation.referral_invitations,
  sentEmails: true,
  emails: '',
  isSendingEmail: false
})

const setEmails = (state, action) => ({
  ...state,
  ...action.data
})

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'API:REFERRAL:FETCH_PROGRAM:SUCCESS':
      return fetchProgramSuccess(state, action)
    case 'API:REFERRAL:SEND_EMAILS:STARTED':
      return sendEmailsStarted(state, action)
    case 'API:REFERRAL:SEND_EMAILS:ERROR':
      return sendEmailsError(state, action)
    case 'API:REFERRAL:SEND_EMAILS:SUCCESS':
      return sendEmailsSuccess(state, action)
    case 'REFERRAL:SET_EMAILS':
      return setEmails(state, action)
    default:
      return state
  }
}

export default reducer
