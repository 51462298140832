import { connect } from 'react-redux'
import { pure, lifecycle, compose } from 'recompose'
import { addPlatformSelector } from 'src/app/lib/user_agent_selectors'
import Store from 'src/app/store/store.js'
import WithErrorHandle from 'src/app/components/HOC/with_errorhandle/witherrorhandle'

const enhance = compose(
  WithErrorHandle,
  connect(),
  pure,
  lifecycle({
    componentWillMount() {
      window._hmt.push(['_setAutoPageview', true])
    },
    componentDidMount() {
      addPlatformSelector(Store)
    }
  })
)

export default enhance(({ children }) => (
  <div>
    {/* global_layouts */}
    {children}
  </div>
))
