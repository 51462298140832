const fakeProduct = id => {
  return {
    id,
    type: 'clothing',
    brand: { name: '' },
    catalogue_photos: [{ retina_index_url: '' }]
  }
}

const initialCollectionLoadingProducts = () => {
  return [
    fakeProduct(1),
    fakeProduct(2),
    fakeProduct(3),
    fakeProduct(4),
    fakeProduct(5)
  ]
}

const initialState = {
  tote: {},
  isMissingTote: false,
  primaryGallery: [],
  secondaryGallery: [],
  products: {},
  collections: [
    {
      title: "We think you'll love...",
      products: initialCollectionLoadingProducts()
    },
    {
      title: 'New for you',
      products: initialCollectionLoadingProducts()
    },
    {
      title: 'Wear it again',
      products: initialCollectionLoadingProducts()
    }
  ],
  filters: {
    in_stock: true,
    colors: [],
    filter_terms: ['clothing'],
    maternity: 'false',
    weather: [],
    without_weather: [],
    sort: 'recommended',
    primaryPage: 1,
    primaryPagesRemaining: true,
    secondaryPage: 1,
    secondaryPagesRemaining: true,
    changedFilters: false
  },
  filterModalOpen: false,
  toteModalOpen: false,
  toteModalSwapMode: false,
  selectedProduct: {},
  locked: false,
  locking: false,
  loading: false,
  closetCollection: [],
  closetCollectionFetched: false,
  fetchNonClosetCollections: true,
  swappingId: undefined,
  swappedId: undefined,
  errorModalOpen: false,
  confirmationModalOpen: false,
  sourceSwapModule: undefined
}

function setFilters(state, action) {
  let filters = {
    ...state.filters,
    changedFilters: true,
    ...action.filters,
    primaryPage: 1,
    secondaryPage: 1,
    primaryPagesRemaining: true,
    secondaryPagesRemaining: true
  }
  return { ...state, filters }
}

function clearFilters(state) {
  let tote = { ...state.tote },
    filters = {
      ...initialState.filters,
      weather: seasonsToWeather(tote.seasons),
      maternity: tote.maternity,
      primaryPage: 1,
      secondaryPage: 1,
      primaryPagesRemaining: true,
      secondaryPagesRemaining: true,
      changedFilters: false
    }
  return { ...state, filters, primaryGallery: [], secondaryGallery: [] }
}

function clearGallery(state) {
  return {
    ...state,
    primaryGallery: [],
    secondaryGallery: [],
    primaryPage: 1,
    secondaryPage: 1,
    primaryPagesRemaining: true,
    secondaryPagesRemaining: true
  }
}

function setPrimaryGallery(state, action) {
  let filters = { ...state.filters },
    primaryGallery = [...state.primaryGallery]

  filters.primaryPage = filters.primaryPage + 1

  if (action.data.resetGallery) {
    primaryGallery = []
    var secondaryGallery = []
    var primaryPage = 1
    var secondaryPage = 1
    var primaryPagesRemaining = true
    var secondaryPagesRemaining = true
  }
  if (action.response.data.products.length < 20) {
    filters.primaryPagesRemaining = false
  }

  _.each(action.response.data.products, product => {
    primaryGallery.push(product)
  })

  primaryGallery = _.uniqBy(primaryGallery, product => product.id)

  if (action.data.resetGallery) {
    return {
      ...state,
      filters,
      primaryGallery,
      loading: false,
      secondaryGallery,
      primaryPage,
      secondaryPage,
      primaryPagesRemaining,
      secondaryPagesRemaining
    }
  }

  return { ...state, filters, primaryGallery, loading: false }
}

function setSecondaryGallery(state, action) {
  let filters = { ...state.filters },
    secondaryGallery = [...state.secondaryGallery]

  filters.secondaryPage = filters.secondaryPage + 1

  if (action.response.data.products.length < 20) {
    filters.secondaryPagesRemaining = false
  }

  _.each(action.response.data.products, product => {
    secondaryGallery.push(product)
  })

  secondaryGallery = _.uniqBy(secondaryGallery, product => product.id)

  return { ...state, filters, secondaryGallery, loading: false }
}

function setProduct(state, action) {
  const product = action.response.data.product
  let products = { ...state.products }
  products[product.id] = product
  let selectedProduct = { ...product }
  //if tote is in state, and product is in tote, set size to tote product size
  if (!_.isEmpty(state.tote) && !_.isEmpty(state.product)) {
    const toteProductInTote = _.find(state.tote.tote_products, tote_product => {
      return tote_product.product.id === state.product.id
    })
    if (toteProductInTote) {
      selectedProduct.size = toteProductInTote.product_item.product_size.size
    } else {
      selectedProduct.size = product.recommended_size
    }
  } else {
    selectedProduct.size = product.recommended_size
  }
  return { ...state, products, selectedProduct }
}

function setSelectedProduct(state, action) {
  const selectedProduct = {
    ...state.selectedProduct,
    id: action.productId,
    size: action.productSize,
    type: action.productType
  }
  return { ...state, selectedProduct }
}

function enterSwapMode(state, action) {
  let selectedProduct = { ...state.selectedProduct }

  if (action.productId) {
    const allSources = [
        ...state.primaryGallery,
        ...state.secondaryGallery,
        ..._.map(state.tote.tote_products, tp => tp.product),
        ..._.flatten(_.map(state.collections, c => c.products)),
        ...state.closetCollection
      ],
      product = _.find(allSources, p => p.id === action.productId),
      size = action.productSize,
      {
        id,
        type,
        catalogue_photos,
        brand,
        title,
        category,
        bust,
        waist,
        hip,
        front_length,
        back_length,
        details
      } = product

    selectedProduct = {
      id,
      size,
      type,
      catalogue_photos,
      brand,
      title,
      category,
      bust,
      waist,
      hip,
      front_length,
      back_length,
      details
    }
  }

  let sourceSwapModule = action.sourceSwapModule

  return {
    ...state,
    toteModalOpen: true,
    toteModalSwapMode: true,
    sourceSwapModule,
    selectedProduct
  }
}

function toggleToteModal(state, action) {
  return {
    ...state,
    toteModalOpen: !state.toteModalOpen,
    toteModalSwapMode: false,
    swappedId: undefined
  }
}

function closeToteModal(state, action) {
  return {
    ...state,
    toteModalOpen: false,
    toteModalSwapMode: false,
    swappedId: undefined
  }
}

function setTote(state, action) {
  let tote = { ...action.response.data.customizeable_tote },
    filters = { ...state.filters }
  const { tote_products } = tote,
    clothing = _.filter(tote_products, t => t.product.type === 'Clothing'),
    accessories = _.filter(tote_products, t => t.product.type === 'Accessory')
  tote.tote_products = [...clothing, ...accessories]
  filters.weather = seasonsToWeather(tote.seasons)
  return { ...state, tote, filters }
}

function removeProductSuccess(state, action) {
  let tote = { ...state.tote },
    { tote_products } = tote,
    oldToteProductId = action.response.id

  tote_products = _.filter(tote_products, tote_product => {
    return tote_product.id !== oldToteProductId
  })

  tote.tote_products = tote_products

  return {
    ...state,
    tote
  }
}

function swapProductSuccess(state, action) {
  let tote = { ...state.tote },
    { tote_products } = tote,
    oldToteProductId = action.data.toteProductId

  const newToteProduct = { ...action.response }

  let oldProductIndex = _.findIndex(
    tote_products,
    t => t.id === oldToteProductId
  )

  tote_products = _.filter(tote_products, tote_product => {
    return tote_product.id !== oldToteProductId
  })

  tote.tote_products = [
    ..._.take(tote_products, oldProductIndex),
    newToteProduct,
    ..._.drop(tote_products, oldProductIndex)
  ]
  return {
    ...state,
    selectedProduct: {},
    tote,
    toteModalSwapMode: false,
    swappingId: undefined,
    swappedId: newToteProduct.id
  }
}

function swapProductError(state, action) {
  return {
    ...state,
    toteModalSwapMode: false,
    swappingId: undefined,
    toteModalOpen: false,
    errorModalOpen: true
  }
}

function addProductSuccess(state, action) {
  let tote = { ...state.tote }

  const { tote_products } = tote,
    newToteProduct = { ...action.response }

  const clothing = _.filter(tote_products, t => t.product.type === 'Clothing'),
    accessories = _.filter(tote_products, t => t.product.type === 'Accessory')

  if (newToteProduct.product.type === 'Clothing') {
    tote.tote_products = [...clothing, newToteProduct, ...accessories]
  } else {
    tote.tote_products = [...clothing, ...accessories, newToteProduct]
  }

  return {
    ...state,
    selectedProduct: {},
    tote,
    toteModalSwapMode: false,
    swappingId: undefined,
    swappedId: newToteProduct.id
  }
}

function addProductError(state, action) {
  return {
    ...state,
    selectedProduct: {},
    toteModalSwapMode: false,
    swappingId: undefined,
    toteModalOpen: false,
    errorModalOpen: true
  }
}

function seasonsToWeather(seasons) {
  let weather = []
  _.each(seasons, season => {
    switch (season) {
      case 'summer':
        weather.push('warm')
        break
      case 'winter':
        weather.push('cool')
        break
      default:
        weather.push('mild')
        break
    }
  })
  return weather
}

function setCollections(state, action) {
  return {
    ...state,
    fetchNonClosetCollections: false,
    loading: false,
    collections: action.response.data.tote_swap_collections
  }
}

function storeClosetCollection(state, action) {
  let closetCollection = action.response.data.products
  return {
    ...state,
    closetCollection,
    loading: false,
    closetCollectionFetched: true
  }
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'API:CLOSET:FETCH_COLLECTION:STARTED':
      return { ...state, loading: true }
    case 'API:CLOSET:FETCH_COLLECTION:SUCCESS':
      return storeClosetCollection(state, action)
    case 'API:TOTES:FETCH_LATEST:SUCCESS':
      return setTote(state, action)
    case 'API:TOTES:FETCH_LATEST:ERROR':
      return { ...state, isMissingTote: true }
    case 'API:TOTE_SWAP:ADD_PRODUCT:ERROR':
      return addProductError(state, action)
    case 'API:TOTE_SWAP:ADD_PRODUCT:STARTED':
      return { ...state, swappingId: 'add' }
    case 'API:TOTE_SWAP:ADD_PRODUCT:SUCCESS':
      return addProductSuccess(state, action)
    case 'API:TOTE_SWAP:FETCH_COLLECTIONS:STARTED':
      return { ...state, fetchNonClosetCollections: false }
    case 'API:TOTE_SWAP:FETCH_COLLECTIONS:SUCCESS':
      return setCollections(state, action)
    case 'API:TOTE_SWAP:FETCH_PRODUCT:SUCCESS':
      return setProduct(state, action)
    case 'API:TOTE_SWAP:FETCH_PRODUCTS:STARTED':
      return { ...state, loading: true }
    case 'API:TOTE_SWAP:FETCH_PRODUCTS:SUCCESS':
      return setPrimaryGallery(state, action)
    case 'API:TOTE_SWAP:FETCH_MORE_PRODUCTS:STARTED':
      return { ...state, loading: true }
    case 'API:TOTE_SWAP:FETCH_MORE_PRODUCTS:SUCCESS':
      return setSecondaryGallery(state, action)
    case 'API:TOTE_SWAP:LOCK_TOTE:ERROR':
      return { ...state, locking: false }
    case 'API:TOTE_SWAP:LOCK_TOTE:STARTED':
      return { ...state, locking: true }
    case 'API:TOTE_SWAP:LOCK_TOTE:SUCCESS':
      return { ...state, locked: true }
    case 'API:TOTE_SWAP:REMOVE_PRODUCT:SUCCESS':
      return removeProductSuccess(state, action)
    case 'API:TOTE_SWAP:SWAP_PRODUCT:ERROR':
      return swapProductError(state, action)
    case 'API:TOTE_SWAP:SWAP_PRODUCT:STARTED':
      return { ...state, swappingId: action.data.toteProductId }
    case 'API:TOTE_SWAP:SWAP_PRODUCT:SUCCESS':
      return swapProductSuccess(state, action)
    case 'TOTE_SWAP:CLEAR_FILTERS':
      return clearFilters(state, action)
    case 'TOTE_SWAP:CLEAR_GALLERY_PRODUCTS':
      return clearGallery(state)
    case 'TOTE_SWAP:CLOSE_ERROR_MODAL':
      return { ...state, errorModalOpen: false, selectedProduct: {} }
    case 'TOTE_SWAP:SHOW_CONFIRMATION_MODAL':
      return { ...state, confirmationModalOpen: true }
    case 'TOTE_SWAP:CLOSE_CONFIRMATION_AND_TOTE_MODALS':
      return { ...state, confirmationModalOpen: false, toteModalOpen: false }
    case 'TOTE_SWAP:ENTER_SWAP_MODE':
      return enterSwapMode(state, action)
    case 'TOTE_SWAP:SET_FILTERS':
      return setFilters(state, action)
    case 'TOTE_SWAP:SET_LOADING':
      return { ...state, loading: action.value }
    case 'TOTE_SWAP:SET_SELECTED_PRODUCT':
      return setSelectedProduct(state, action)
    case 'TOTE_SWAP:TOGGLE_FILTER_MODAL':
      return { ...state, filterModalOpen: !state.filterModalOpen }
    case 'TOTE_SWAP:TOGGLE_TOTE_MODAL':
      return toggleToteModal(state, action)
    case 'TOTE_SWAP:CLOSE_TOTE_MODAL':
      return closeToteModal(state, action)
    default:
      return state
  }
}

export default reducer
