const initialState = {
  tote: {},
  ratingsFlow: []
}

const setToteRating = (state, action) => {
  return {
    ...state,
    tote: {
      ...state.tote,
      tote_rating: {
        ...state.tote.tote_rating,
        rating: action.data.variables.tote_rating.rating
      }
    }
  }
}

const updateProductRating = (state, action) => {
  //find and update the tote_product's rating
  let tote_product = _.find(state.tote.tote_products, {
    id: parseInt(action.data.tote_product_id, 10)
  })
  tote_product.rating = {
    ...tote_product.rating,
    ...action.data.rating
  }

  //replace tote product with a tote product that has an updated rating
  const tote_products = _.extend(state.tote.tote_products, tote_product)

  return {
    ...state,
    tote: {
      ...state.tote,
      tote_products
    }
  }
}

const updateRatingsFlow = state => {
  const flow = {
    rental: ['wears', 'style', 'issues'],
    purchase: ['style', 'issues']
  }

  const ratingsFlow = !state.tote.rental ? flow['purchase'] : flow['rental']
  return { ...state, ratingsFlow }
}

const updateToteRating = (state, action) => {
  return {
    ...state,
    tote: {
      ...state.tote,
      tote_rating: {
        ...state.tote.tote_rating,
        ...action.data.toteRating
      }
    }
  }
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'API:TOTES:FETCH_TOTE:SUCCESS':
      return { ...state, tote: { ...action.response.data.tote } }
    case 'API:RATINGS:SUBMIT_TOTE_RATING:STARTED':
      return setToteRating(state, action)
    case 'RATINGS:UPDATE_FLOW':
      return updateRatingsFlow(state)
    case 'RATINGS:UPDATE_PRODUCT_RATING':
      return updateProductRating(state, action)
    case 'RATINGS:UPDATE_TOTE_RATING':
      return updateToteRating(state, action)
    default:
      return state
  }
}

export default reducer
