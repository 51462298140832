const initialState = {
  clothingFilterTerms: [],
  accessoryFilterTerms: []
}

function reducer(state = initialState, action) {
  const { response } = action
  switch (action.type) {
    case 'API:PRODUCT_FILTER_TERMS:FETCH:SUCCESS':
      return storeProductFilterTerms(state, response)
    default:
      return state
  }
}

function storeProductFilterTerms(state, productFilterTerms) {
  const clothingFilterTerms = _.map(
      _.filter(productFilterTerms, function(term) {
        return term.type === 'clothing'
      }),
      'filter_term'
    ),
    accessoryFilterTerms = _.map(
      _.filter(productFilterTerms, function(term) {
        return term.type === 'accessory'
      }),
      'filter_term'
    )

  return {
    ...state,
    clothingFilterTerms,
    accessoryFilterTerms
  }
}

export default reducer
