const initialState = {
  firstName: ''
}

const getReferrerNameSuccess = (state, action) => ({
  ...state,
  firstName: action.response.data.referrer
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'API:REFERRER:GET_REFERRER_NAME:SUCCESS':
      return getReferrerNameSuccess(state, action)
    default:
      return state
  }
}

export default reducer
