let _global = undefined

if (typeof global !== 'undefined') _global = global
if (typeof window !== 'undefined') _global = window

_global.ENV = 'development'

_global.LeToteExperiments = { newOnboardingBundle: false }

_global.analytics = {
  track: function(category, opt_label, action = null) {
    window._hmt &&
      window._hmt.push([
        '_trackEvent',
        category,
        action || category,
        JSON.stringify(opt_label)
      ])
  },
  trackLink: function(link, describe, object) {}
}

//NOTE: download Android apk package and ios adress

_global.android_url = 'https://static.letote.cn/down/letote_release_6.40.0.apk'

_global.iOS_url =
  'https://itunes.apple.com/cn/app/%E6%89%98%E7%89%B9%E8%A1%A3%E7%AE%B1/id1360599865?mt=8'

// NOTE: tencent pkg market url
_global.tencent_market_url =
  'https://a.app.qq.com/o/simple.jsp?pkgname=com.letotecn'

export default _global
