const initialState = {
  showHeader: true,
  showFooter: true
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'NAV:HEADER:DISABLE':
      return { ...state, showHeader: false }
    case 'NAV:HEADER:ENABLE':
      return { ...state, showHeader: true }
    case 'NAV:FOOTER:DISABLE':
      return { ...state, showFooter: false }
    case 'NAV:FOOTER:ENABLE':
      return { ...state, showFooter: true }
    default:
      return state
  }
}

export default reducer
