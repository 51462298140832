const PER_PAGE = 20
const initialState = {
  loading: true,
  more: true,
  filterModalOpen: false
}

const initialStoreClothingTypesDetail = (state, action) => {
  const newProducts = action.response.data.products
  const oldFilters = action.data.variables.filters
  return {
    ...state,
    products: newProducts,
    filters: { ...oldFilters, page: 2 },
    loading: false,
    more: newProducts.length === PER_PAGE
  }
}

const storeClothingTypesDetail = (state, action) => {
  let oldProducts = state.products,
    newProducts = action.response.data.products,
    page = state.filters.page + 1,
    filters = {
      ...state.filters,
      page: page
    },
    more = newProducts.length === PER_PAGE,
    products = [...oldProducts, ...newProducts]

  return {
    ...state,
    products: products,
    filters: filters,
    loading: false,
    more: more
  }
}

const clearProducts = (state, action) => {
  const filters = {
    ...state.filters,
    page: 1
  }
  return {
    ...state,
    products: [],
    filters: filters,
    loading: true,
    more: true
  }
}

const setFilters = (state, action) => {
  let filters = {
    ...state.filters,
    ...action.filters
  }
  return { ...state, filters }
}

function reducer(state = initialState, action) {
  const { type } = action
  switch (type) {
    case 'API:CLOTHING_TYPES_DETAIL:INITIAL_FETCH:STARTED':
      return { ...state, loading: true, products: [] }
    case 'API:CLOTHING_TYPES_DETAIL:INITIAL_FETCH:ERROR':
      return { ...initialState, ...state }
    case 'API:CLOTHING_TYPES_DETAIL:INITIAL_FETCH:SUCCESS':
      return initialStoreClothingTypesDetail(state, action)
    case 'API:CLOTHING_TYPES_DETAIL:FETCH:STARTED':
      return { ...state, loading: true }
    case 'API:CLOTHING_TYPES_DETAIL:FETCH:ERROR':
      return { ...initialState, ...state }
    case 'API:CLOTHING_TYPES_DETAIL:FETCH:SUCCESS':
      return storeClothingTypesDetail(state, action)
    case 'CLOTHING_TYPES_DETAIL:CLEAR_PRODUCTS':
      return clearProducts(state, action)
    case 'CLOTHING_TYPES_DETAIL:TOGGLE_FILTER_MODAL':
      return {
        ...state,
        filterModalOpen: !state.filterModalOpen
      }
    case 'CLOTHING_TYPES_DETAIL:SET_FILTERS':
      return setFilters(state, action)
    default:
      return { ...initialState, ...state }
  }
}

export default reducer
