const initialState = {
  page: 1,
  customer: {
    first_name: '',
    last_name: ''
  },
  shipping_address: {
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip_code: '',
    telephone: ''
  },
  full_name: '',
  full_number: '',
  expiration_date: '',
  cvv: '',
  useSameAddress: true,
  billing_address: {
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip_code: ''
  },
  insurance: true,
  cvvModalOpen: false,
  isSubmitting: false,
  isSuccess: false,
  error: undefined
}

const setCustomerFromFetch = (oldState, action) => {
  const _customer = action.response
  const customer = _.mapValues(
    _.pick(_customer, 'first_name', 'last_name'),
    val => (val ? val : '')
  )
  const full_name = _customer.first_name
    ? `${customer.first_name} ${customer.last_name}`
    : ''
  const shipping_address = _.mapValues(
    _.pick(
      _customer.address,
      'address_1',
      'address_2',
      'city',
      'state',
      'zip_code',
      'telephone'
    ),
    val => (val ? val : '')
  )

  return {
    ...oldState,
    customer,
    full_name,
    shipping_address: {
      ...oldState.shipping_address,
      ...shipping_address
    }
  }
}

const checkoutStarted = state => {
  return {
    ...state,
    isSubmitting: true
  }
}

const checkoutSuccess = state => {
  return {
    ...state,
    isSubmitting: false,
    isSuccess: true
  }
}

const checkoutError = (state, action) => {
  const { errors } = action.response
  let message = 'Something went wrong!'
  if (errors && errors[0]) message = errors[0].message
  return {
    ...state,
    isSubmitting: false,
    error: message
  }
}

const setCheckout = (state, action) => {
  const newState = {
    ...state,
    ...action.data
  }

  return {
    ...newState
  }
}

const setCustomer = (state, action) => {
  const newState = {
    ...state,
    customer: {
      ...state.customer,
      ...action.data
    }
  }

  return {
    ...newState,
    full_name: `${newState.customer.first_name} ${newState.customer.last_name}`
  }
}

const setShippingAddress = (state, action) => {
  return {
    ...state,
    shipping_address: {
      ...state.shipping_address,
      ...action.data
    }
  }
}

const shippingZipCodeSuccess = (state, action) => {
  return {
    ...state,
    shipping_address: {
      ...state.shipping_address,
      city: action.response.places[0]['place name'],
      state: action.response.places[0]['state abbreviation']
    }
  }
}

const toggleSameAddress = state => {
  return {
    ...state,
    useSameAddress: !state.useSameAddress
  }
}

const setBillingAddress = (state, action) => {
  return {
    ...state,
    billing_address: {
      ...state.billing_address,
      ...action.data
    }
  }
}

const billingZipCodeSuccess = (state, action) => {
  return {
    ...state,
    billing_address: {
      ...state.billing_address,
      city: action.response.places[0]['place name'],
      state: action.response.places[0]['state abbreviation']
    }
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'API:CUSTOMER:FETCH:SUCCESS':
      return setCustomerFromFetch(state, action)
    case 'API:PURCHASE_CHECKOUT:SUBMIT:STARTED':
      return checkoutStarted(state, action)
    case 'API:PURCHASE_CHECKOUT:SUBMIT:SUCCESS':
      return checkoutSuccess(state, action)
    case 'API:PURCHASE_CHECKOUT:SUBMIT:ERROR':
      return checkoutError(state, action)
    case 'API:PURCHASE_CHECKOUT:SHIPPING_ZIP_CODE:SUCCESS':
      return shippingZipCodeSuccess(state, action)
    case 'API:PURCHASE_CHECKOUT:BILLING_ZIP_CODE:SUCCESS':
      return billingZipCodeSuccess(state, action)
    case 'PURCHASE_CHECKOUT:SET_CHECKOUT':
      return setCheckout(state, action)
    case 'PURCHASE_CHECKOUT:SET_CUSTOMER':
      return setCustomer(state, action)
    case 'PURCHASE_CHECKOUT:SET_SHIPPING_ADDRESS':
      return setShippingAddress(state, action)
    case 'PURCHASE_CHECKOUT:TOGGLE_SAME_ADDRESS':
      return toggleSameAddress(state, action)
    case 'PURCHASE_CHECKOUT:SET_BILLING_ADDRESS':
      return setBillingAddress(state, action)
    case 'PURCHASE_CHECKOUT:SET_INSURANCE:ON':
      return { ...state, insurance: true }
    case 'PURCHASE_CHECKOUT:SET_INSURANCE:OFF':
      return { ...state, insurance: false }
    case 'PURCHASE_CHECKOUT:SET_SAME_ADDRESS:ON':
      return { ...state, useSameAddress: true }
    case 'PURCHASE_CHECKOUT:SET_SAME_ADDRESS:OFF':
      return { ...state, useSameAddress: false }
    default:
      return state
  }
}

export default reducer
