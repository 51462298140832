const initialState = {
  activeScreen: 'OnboardingStart',
  conditions: null,
  city: null,
  free_tote_landing_page: false,
  fromCustomerInvitation: false,
  isLoggingIn: false,
  modalIsOpen: false,
  onboardingFlow: [],
  showStickyHeader: false,
  successRedirect: '/',
  state: null,
  withheld_facebook_email: false,
  zipCode: '',
  zipCodeLocation: null,
  zipCodeStatus: null
}

function reducer(state = {}, action) {
  const { response, data } = action
  switch (action.type) {
    case 'API:ONBOARDING:FETCH_WEATHER_CONDITIONS:SUCCESS':
      return updateWeatherConditions(state, action)
    case 'ONBOARDING:INIT':
      return onboardingInit(state, data)
    case 'ONBOARDING:SET_IS_LOGGING_IN':
      return setIsLoggingIn(state, data)
    case 'ONBOARDING:SET_ACTIVE_SCREEN':
      return setActiveScreen(state, data)
    case 'ONBOARDING:SET_ONBOARDING_FROM_CART':
      return { ...state, onboardingFromCart: data }
    case 'ONBOARDING:STORE_ZIPCODE':
      return storeZipCode(state, data)
    case 'ONBOARDING:STORE_ZIPCODE_V2':
      return storeZipCodeV2(state, data)
    case 'ONBOARDING:UPDATE_FLOW':
      return updateFlow(state, data)
    case 'ONBOARDING:TOGGLE_HEADER':
      return toggleHeader(state, data)
    case 'ONBOARDING:TOGGLE_MODAL':
      return toggleModal(state, data)
    case 'API:ONBOARDING:GET_ZIP_DATA:ERROR':
    case 'API:SESSION:SUBMIT_EMAIL_AND_PASSWORD:STARTED':
      return setIsSubmittingEmailAndPassword(state, true)
    case 'API:SESSION:SUBMIT_EMAIL_AND_PASSWORD:ERROR':
      return setIsSubmittingEmailAndPassword(state, false)
    case 'API:SUBMIT_STYLE_PROFILE:SUCCESS':
    case 'API:SESSION:SUBMIT_EMAIL_AND_PASSWORD:SUCCESS':
      return setSuccessRedirectPath(state, response)
    default:
      // sets initialState when state already initialized by store
      return { ...initialState, ...state }
  }
}

const updateWeatherConditions = (state, action) => {
  const weatherConditions = action.response.data.weather_conditions

  return {
    ...state,
    conditions: weatherConditions.conditions,
    zipCodeLocation: weatherConditions.location
  }
}

function onboardingInit(state, data) {
  return _.extend({}, state, {
    activeScreen: data.activeScreen,
    onboardingFlow: data.onboardingFlow,
    signInFlow: data.signInFlow
  })
}

function setIsLoggingIn(state, isLoggingIn) {
  return _.extend({}, state, {
    isLoggingIn: isLoggingIn
  })
}

function setActiveScreen(state, activeScreen) {
  return _.extend({}, state, {
    activeScreen: activeScreen
  })
}

function storeZipCode(oldState, { zipCode, city, state, zipCodeStatus }) {
  return { ...oldState, zipCode, city, state, zipCodeStatus }
}

function storeZipCodeV2(state, zipCode) {
  return { ...state, zipCode }
}

function updateFlow(state, onboardingFlow) {
  return { ...state, onboardingFlow }
}

function toggleModal(state, toggleModal) {
  return { ...state, modalIsOpen: toggleModal }
}

function toggleHeader(state, toggleHeader) {
  return { ...state, showStickyHeader: toggleHeader }
}

function setSuccessRedirectPath(state, data) {
  if (!data || !data.success_redirect_path) return state

  return _.extend({}, state, {
    successRedirect: data.success_redirect_path
  })
}

function setIsSubmittingEmailAndPassword(state, isSubmittingEmailPassword) {
  return _.extend({}, state, {
    isSubmittingEmailPassword: isSubmittingEmailPassword
  })
}

export default reducer
