const initialState = {
  message: ''
}

function reducer(state = initialState, action) {
  const { data, response } = action
  switch (action.type) {
    case 'ERRORS:SET_MESSAGE':
      return setErrorMessage(state, data)
    case (action.type.match(/^API:.*:ERROR$/) || {}).input:
      return handleApiError(state, response)
    case 'ONBOARDING:SET_ACTIVE_SCREEN':
      return clearErrorMessage(state)
    case 'API:SESSION:SUBMIT_EMAIL_AND_PASSWORD:STARTED':
      return clearErrorMessage(state)
    default:
      return state
  }
}

function setErrorMessage(state, message) {
  return _.extend({}, state, {
    message: message
  })
}

function clearErrorMessage(state) {
  return setErrorMessage(state, '')
}

function handleApiError(state, data) {
  if (data && data.responseText) {
    try {
      const errorMessage = JSON.parse(data.responseText).message
      return setErrorMessage(state, errorMessage)
    } catch (e) {
      return setErrorMessage(state, 'Something went wrong. Please try again.')
    }
  }
  return state
}

export default reducer
