const initialState = {
  first_name: '',
  referral_url: '',
  products: []
}

const updateToteShare = (state, action) => {
  const toteShare = action.response.data.tote_share

  return {
    ...state,
    ...toteShare
  }
}

function reducer(state = {}, action) {
  switch (action.type) {
    case 'API:TOTE_SHARE:FETCH:SUCCESS':
      return updateToteShare(state, action)
    default:
      // sets initialState when state already initialized by store
      return { ...initialState, ...state }
  }
}

export default reducer
