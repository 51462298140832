import { Router, Route, IndexRoute, browserHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import { Provider } from 'react-redux'
import Store, { persistor } from 'src/app/store/store.js'
import { PersistGate } from 'redux-persist/es/integration/react'
import * as AsyncComponents from './ImportComponents'
import Layout from 'src/app/containers/Layout'

const history = syncHistoryWithStore(browserHistory, Store)

const onEnterHook = (nextState, replace) => {
  const title = nextState.routes[nextState.routes.length - 1].title
  if (title) document.title = title
}

const onChangeHook = (preState, nextState, replace) => {
  const title = nextState.routes[nextState.routes.length - 1].title
  if (title) document.title = title
}

export default () => (
  <Provider store={Store}>
    <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
      <Router history={history}>
        <Route
          path="/"
          component={Layout}
          onEnter={onEnterHook}
          onChange={onChangeHook}
        >
          <IndexRoute component={AsyncComponents.AsyncHomepageRouter} />
          <Route
            path="/landing_page"
            component={AsyncComponents.AsyncLandingPage}
          />
          <Route
            path="/excessive_page"
            component={AsyncComponents.AsyncExcessivePage}
          />
          <Route
            path="/sem_one_four_nine"
            component={AsyncComponents.AsyncOneHundredAndFortyNine}
          />
          <Route
            path="/brand"
            component={AsyncComponents.AsyncBrandContainer}
          />
        </Route>
      </Router>
    </PersistGate>
  </Provider>
)
