const initialState = {
  loading: true,
  product_list: []
}

function setCollections(state, action) {
  return {
    ...state,
    loading: false,
    collections: action.response.data.post_tote_swap_upsell_collections
  }
}

function setCartSummary(state, action) {
  return {
    ...state,
    ...action.response.data.post_tote_swap_upsell_cart_summary
  }
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'API:POST_TOTE_SWAP_UPSELL:FETCH_COLLECTIONS:STARTED':
      return { ...state, loading: true }
    case 'API:POST_TOTE_SWAP_UPSELL:FETCH_COLLECTIONS:SUCCESS':
      return setCollections(state, action)
    case 'POST_TOTE_SWAP_UPSELL:SET_TOTE':
      return {
        ...state,
        toteId: action.toteId
      }
    case 'POST_TOTE_SWAP_UPSELL:ADD_TO_CART':
      return {
        ...state,
        product_list: _.union(state.product_list, [action.productId])
      }
    case 'POST_TOTE_SWAP_UPSELL:REMOVE_FROM_CART':
      return {
        ...state,
        product_list: _.without(state.product_list, action.productId)
      }
    case 'API:POST_TOTE_SWAP_UPSELL:FETCH_CART_SUMMARY:SUCCESS':
      return setCartSummary(state, action)
    case 'API:POST_TOTE_SWAP_UPSELL:CHECKOUT:STARTED':
      return { ...state, checkout_started: true }
    case 'API:POST_TOTE_SWAP_UPSELL:CHECKOUT:SUCCESS':
      return { ...state, checkout_successful: true }
    case 'API:POST_TOTE_SWAP_UPSELL:CHECKOUT:ERROR':
      return { ...state, checkout_failed: true }
    default:
      return state
  }
}

export default reducer
