const initialState = {
  items: [],
  isLoaded: false,
  tax: 0.0,
  shipping: 0.0,
  credit_applied: 0.0,
  subtotal: 0.0,
  total_to_pay: 0.0,
  errors: [],
  isSubmitting: false
}

const removeFromCart = (state, action) => {
  let cart = action.response.data.RemoveFromCart.cart
  return {
    ...state,
    items: [...cart.cart_items],
    tax: cart.summary.sales_tax,
    shipping: cart.summary.shipping_cost,
    credit_applied: cart.summary.credit_applied,
    total_to_pay: cart.summary.total_to_pay,
    subtotal: cart.summary.subtotal
  }
}

const fetchCart = (state, action) => {
  let cart = action.response.data.cart
  return {
    ...state,
    items: [...cart.cart_items],
    tax: cart.summary.sales_tax,
    shipping: cart.summary.shipping_cost,
    credit_applied: cart.summary.credit_applied,
    total_to_pay: cart.summary.total_to_pay,
    subtotal: cart.summary.subtotal,
    preorder_expected_shipping_date: cart.preorder_expected_shipping_date,
    isLoaded: true
  }
}

const addToCart = (state, action) => {
  let cart = action.response.data.AddToCart.cart
  return {
    ...state,
    items: [...cart.cart_items],
    tax: cart.summary.sales_tax,
    shipping: cart.summary.shipping_cost,
    credit_applied: cart.summary.credit_applied,
    total_to_pay: cart.summary.total_to_pay,
    subtotal: cart.summary.subtotal
  }
}

const checkoutError = (state, action) => {
  return {
    ...state,
    isSubmitting: false,
    errors: action.response.errors
  }
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'API:CART:FETCH:SUCCESS':
      return fetchCart(state, action)
    case 'API:CART:CHECKOUT:STARTED':
      return { ...state, isSubmitting: true }
    case 'API:CART:CHECKOUT:SUCCESS':
      return { ...state, isSubmitting: false }
    case 'API:CART:CHECKOUT:ERROR':
      return checkoutError(state, action)
    case 'APP:SET_CART':
      return {
        ...state,
        items: action.data
      }
    case 'API:CART:ADD:SUCCESS':
      return addToCart(state, action)
    case 'API:CART:REMOVE:SUCCESS':
      return removeFromCart(state, action)
    default:
      return state
  }
}

export default reducer
