const initialState = {
  isSubmitting: false,
  message: '',
  isSuccess: false
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'API:GIFT_CARDS:APPLY:SUCCESS':
      return {
        ...state,
        message: action.response.message,
        isSubmitting: false,
        isSuccess: true,
        previousAction: 'apply'
      }
    case 'API:GIFT_CARDS:CREATE:SUCCESS':
      return {
        ...state,
        message: action.response.message,
        isSubmitting: false,
        isSuccess: true,
        previousAction: 'create'
      }
    case 'API:GIFT_CARDS:APPLY:ERROR':
    case 'API:GIFT_CARDS:CREATE:ERROR':
      return {
        ...state,
        message: action.response.message,
        isSubmitting: false
      }
    case 'API:GIFT_CARDS:APPLY:STARTED':
    case 'API:GIFT_CARDS:CREATE:STARTED':
      return { ...state, isSubmitting: true }
    default:
      return state
  }
}

export default reducer
