import app from './app_reducer'
import cart from './cart_reducer'
import classicCheckout from './classic_checkout_reducer'
import closet from './closet_reducer'
import clothingTypes from './clothing_type_reducer'
import customer from './customer_reducer'
import customerStyleInfo from './customer_style_info_reducer'
import customerPhotos from './customer_photos_reducer'
import errors from './errors_reducer'
import experiments from './experiment_reducer'
import fullScreenCarousel from './full_screen_carousel_reducer.js'
import homepage from './homepage_reducer'
import giftCards from './gift_cards'
import navigation from './navigation_reducer'
import onboarding from './onboarding_reducer'
import postToteSwapUpsell from './post_tote_swap_upsell_reducer'
import productFilterTerms from './product_filter_terms_reducer'
import products from './products_reducer'
import promoCode from './promo_code_reducer'
import purchaseCheckout from './purchase_checkout_reducer'
import purchaseOrder from './purchase_order_reducer'
import ratings from './ratings_reducer'
import referral from './referral_reducer'
import referrer from './referrer_reducer'
import slides from './slides_reducer'
import subscriptionTypes from './subscription_types_reducer'
import totes from './totes_reducer'
import toteShare from './tote_share_reducer'
import toteSwap from './tote_swap_reducer'
import toteThemes from './tote_themes_reducer'
import transactions from './transactions_reducer'
import sesameCredit from './sesamecredit_reducer'
import tips from './tips_reducer'

import PersistReducers from 'src/app/lib/persist_reducer'

/**
 * persist black list options
 */
const CUSTOMER = ['closet', 'password']
const TOTES = [
  'past_totes_page',
  'loadedLatestTotes',
  'past_totes',
  'latest_rental_tote',
  'is_styling_past_totes'
]

export default {
  app,
  cart,
  classicCheckout,
  closet,
  clothingTypes,
  customer: PersistReducers({
    key: 'customer',
    name: customer,
    blacklist: CUSTOMER
  }),
  customerStyleInfo,
  customerPhotos,
  errors,
  experiments,
  fullScreenCarousel,
  homepage,
  giftCards,
  navigation,
  onboarding,
  postToteSwapUpsell,
  productFilterTerms,
  products,
  promoCode,
  purchaseCheckout,
  purchaseOrder,
  ratings,
  referral,
  referrer,
  slides,
  subscriptionTypes,
  totes: PersistReducers({
    key: 'totes',
    name: totes,
    blacklist: TOTES
  }),
  toteShare,
  toteSwap,
  toteThemes,
  transactions,
  sesameCredit,
  tips
}
