import asyncComponent from './AsyncComponent'

export const AsyncHomepageRouter = asyncComponent(() =>
  import('src/app/containers/home/homepage_router')
)
export const AsyncLandingPage = asyncComponent(() =>
  import('src/app/containers/landing_page')
)
export const AsyncExcessivePage = asyncComponent(() =>
  import('src/app/containers/landing_page/excessive_page')
)
export const AsyncOneHundredAndFortyNine = asyncComponent(() =>
  import(
    'src/app/containers/landing_page/mobile_landing_page/one_hundred_and_forty_nine'
  )
)
export const AsyncBrandContainer = asyncComponent(() =>
  import('src/app/containers/brand')
)
