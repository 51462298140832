const initialState = {
  homepageTopics: {},
  collectionsId: []
}

const reducer = (state = initialState, action) => {
  const { response, type } = action
  switch (type) {
    case 'API:HOMECOLLECTIONS:FETCH:SUCCESS':
      const { browse_collections } = response.data
      const topics = Object.assign({}, state.homepageTopics)
      const array = []
      browse_collections.map((browse_collection, index) => {
        const collectionId = browse_collection && browse_collection.id
        if (collectionId) {
          array.push(collectionId)
          topics[collectionId] = {}
          topics[collectionId].homepageTopicsProducts =
            browse_collection && browse_collection.products
          topics[collectionId].homepageTopicsBanner =
            browse_collection && browse_collection.banner_photo_thumb_url
        }
        // FIXME：warning——map没有返回值
        return null
      })
      return {
        ...state,
        homepageTopics: topics,
        collectionsId: array
      }
    case 'API:HOMECUSTOMERPHOTOS:FETCH:SUCCESS':
      const data = response.data
      return {
        ...state,
        customer_photos: data.customer_photos
      }
    default:
      return { ...initialState, ...state }
  }
}

export default reducer
