const initialState = {
  page: 1,
  customer: {
    first_name: '',
    last_name: ''
  },
  shipping_address: {
    address_1: '',
    address_2: '',
    //FIXME: now not use
    city: 'New York',
    state: 'NY',
    zip_code: '10001',
    telephone: ''
  },
  full_name: '',
  full_number: '',
  expiration_date: '',
  cvv: '',
  useSameAddress: true,
  billing_address: {
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip_code: ''
  },
  orderSummary: {
    subscription_type: {
      base_price: 0
    },
    subtotal: 0,
    tax: 0,
    discount: 0,
    total: 0,
    error: undefined
  },
  monthlySubscriptionType: {
    id: 1
  },
  quarterlySubscriptionType: {},
  insurance: true,
  cvvModalOpen: false,
  isSubmitting: false,
  isSuccess: false,
  freeTote: false,
  errors: [],
  successRedirectUrl: '',
  billQuarterly: false
}

const checkoutStarted = state => ({
  ...state,
  isSubmitting: true,
  errors: []
})

const checkoutSuccess = (state, action) => {
  const successRedirectUrl =
    action.response.data.CreateRentalSubscription.success_redirect_url
  return {
    ...state,
    isSubmitting: false,
    isSuccess: true,
    errors: [],
    successRedirectUrl
  }
}

const checkoutError = (state, action) => {
  const { errors } = action.response
  const errorMessages = errors.map(error => error.message)
  return {
    ...state,
    isSubmitting: false,
    errors: errorMessages
  }
}

const setCheckout = (state, action) => {
  const newState = {
    ...state,
    ...action.data
  }

  return {
    ...newState
  }
}

const setCustomer = (state, action) => {
  const newState = {
    ...state,
    customer: {
      ...state.customer,
      ...action.data
    }
  }

  return {
    ...newState,
    full_name: `${newState.customer.first_name} ${newState.customer.last_name}`
  }
}

const setShippingAddress = (state, action) => ({
  ...state,
  shipping_address: {
    ...state.shipping_address,
    ...action.data
  }
})

const shippingZipCodeSuccess = (state, action) => ({
  ...state,
  shipping_address: {
    ...state.shipping_address,
    city: action.response.places[0]['place name'],
    state: action.response.places[0]['state abbreviation']
  }
})

const setBillingAddress = (state, action) => ({
  ...state,
  billing_address: {
    ...state.billing_address,
    ...action.data
  }
})

const billingZipCodeSuccess = (state, action) => ({
  ...state,
  billing_address: {
    ...state.billing_address,
    city: action.response.places[0]['place name'],
    state: action.response.places[0]['state abbreviation']
  }
})

const fetchSubscriptionPreviewSuccess = (state, action) => ({
  ...state,
  orderSummary: {
    ...action.response.data.subscription_preview
  },
  freeTote: action.response.data.subscription_preview.free_tote
})

const fetchSubscriptionTypeSuccess = (state, action) => {
  const {
    quarterly_subscription_type: quarterlySubscriptionType,
    ...monthlySubscriptionType
  } = action.response.data.subscription_type

  return {
    ...state,
    quarterlySubscriptionType: quarterlySubscriptionType || {},
    monthlySubscriptionType
  }
}

const fetchSubscriptionPreviewError = (state, action) => ({
  ...state,
  orderSummary: {
    ...state.orderSummary,
    error: action.response
  }
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'API:CLASSIC_CHECKOUT:SUBMIT:STARTED':
      return checkoutStarted(state, action)
    case 'API:CLASSIC_CHECKOUT:SUBMIT:SUCCESS':
      return checkoutSuccess(state, action)
    case 'API:CLASSIC_CHECKOUT:SUBMIT:ERROR':
      return checkoutError(state, action)
    case 'API:CLASSIC_CHECKOUT:FETCH_SUBSCRIPTION_PREVIEW:SUCCESS':
      return fetchSubscriptionPreviewSuccess(state, action)
    case 'API:CLASSIC_CHECKOUT:FETCH_SUBSCRIPTION_PREVIEW:ERROR':
      return fetchSubscriptionPreviewError(state, action)
    case 'API:CLASSIC_CHECKOUT:FETCH_SUBSCRIPTION_TYPE:SUCCESS':
      return fetchSubscriptionTypeSuccess(state, action)
    case 'CLASSIC_CHECKOUT:SET_SHIPPING_ADDRESS':
      return setShippingAddress(state, action)
    case 'CLASSIC_CHECKOUT:SET_BILLING_ADDRESS':
      return setBillingAddress(state, action)
    case 'API:CLASSIC_CHECKOUT:SHIPPING_ZIP_CODE:SUCCESS':
      return shippingZipCodeSuccess(state, action)
    case 'API:CLASSIC_CHECKOUT:BILLING_ZIP_CODE:SUCCESS':
      return billingZipCodeSuccess(state, action)
    case 'CLASSIC_CHECKOUT:SET_BILL_QUARTERLY:ON':
      return {
        ...state,
        billQuarterly: true,
        orderSummary: { ...state.orderSummary, discount: 0 }
      }
    case 'CLASSIC_CHECKOUT:SET_BILL_QUARTERLY:OFF':
      return { ...state, billQuarterly: false }
    case 'CLASSIC_CHECKOUT:SET_CHECKOUT':
      return setCheckout(state, action)
    case 'CLASSIC_CHECKOUT:SET_CUSTOMER':
      return setCustomer(state, action)
    case 'CLASSIC_CHECKOUT:SET_INSURANCE:ON':
      return { ...state, insurance: true }
    case 'CLASSIC_CHECKOUT:SET_INSURANCE:OFF':
      return { ...state, insurance: false }
    case 'CLASSIC_CHECKOUT:SET_SAME_ADDRESS:ON':
      return { ...state, useSameAddress: true }
    case 'CLASSIC_CHECKOUT:SET_SAME_ADDRESS:OFF':
      return { ...state, useSameAddress: false }
    default:
      return state
  }
}

export default reducer
