const initialState = {
  items: [],
  products: {}
}

const storeProduct = (state, action) => {
  let items = [...state.items],
    products = { ...state.products },
    newProduct = action.response.data.product

  products[newProduct.id] = { ...newProduct }

  return { ...state, items, products }
}

const updateProduct = (state, action) => {
  let products = { ...state.products },
    productWithSizing = action.response.data.product

  products[productWithSizing.id] = {
    ...products[productWithSizing.id],
    ...productWithSizing
  }

  return { ...state, products }
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'API:PRODUCTS:FETCH:SUCCESS':
      return storeProduct(state, action)
    case 'API:PRODUCTS:RECOMMENDED_SIZE:FETCH:SUCCESS':
      return updateProduct(state, action)
    default:
      return state
  }
}

export default reducer
