const initialState = []

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'API:SLIDES:FETCH:SUCCESS': {
      let data = action.response || action.resp
      return data
    }
    default:
      return state
  }
}

export default reducer
