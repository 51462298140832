const initialState = {
  themes: {},
  themeIds: []
}

const buildThemeIdArray = toteThemes => toteThemes.map(theme => theme.id)

const buildThemeObjects = toteThemes =>
  toteThemes.reduce((acc, theme) => {
    acc[theme.id] = theme
    return acc
  }, {})

const fetchThemes = (state, action) => {
  const toteThemes = action.response.data.tote_themes
  return {
    ...state,
    themes: buildThemeObjects(toteThemes),
    themeIds: buildThemeIdArray(toteThemes)
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'API:TOTE_THEMES:FETCH:SUCCESS':
      return fetchThemes(state, action)
    case 'TOTE_THEMES:SELECT':
      return { ...state, selectedThemeId: action.data.theme.id }
    default:
      return state
  }
}
