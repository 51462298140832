const initialState = {
  comment: '',
  discountAmount: 0,
  error: '',
  isSubmitting: false,
  isSuccess: false,
  promoState: 'collapsed',
  SELECT_TOTAL_PRICE: 20,
  slide: 1,
  code: '',
  promoCodeError: ''
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'API:PURCHASE_CHECKOUT:SUBMIT:SUCCESS':
      return { ...state, slide: 5 }
    case 'API:PURCHASE_ORDER:SUBMIT:STARTED':
      return { ...state, isSubmitting: true, error: '' }
    case 'API:PURCHASE_ORDER:SUBMIT:SUCCESS':
      return { ...state, isSuccess: true }
    case 'API:PURCHASE_ORDER:SUBMIT:ERROR':
      return {
        ...state,
        isSubmitting: false,
        error: action.response.errors[0].message
      }
    case 'PURCHASE_ORDER:SET_COMMENT':
      return { ...state, comment: action.comment }
    case 'PURCHASE_ORDER:SET_SLIDE':
      return { ...state, slide: action.slide }
    case 'PURCHASE_ORDER:SET_ERROR':
      return { ...state, error: action.error }
    default:
      return state
  }
}

export default reducer
