const initialState = {
  isLoaded: false,
  items: [],
  itemsById: {},
  currentTransactionId: -1
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'API:TRANSACTIONS:FETCH:SUCCESS':
      return addAllTransactions(state, action)
    case 'API:TRANSACTION:GET:SUCCESS':
      return addSingleTransaction(state, action)
    default:
      return state
  }
}

function addAllTransactions(state, action) {
  let itemsById = {}
  _.each(action.response.transactions, transaction => {
    itemsById[transaction.id] = transaction
  })
  const items = action.response.transactions
  return { ...state, itemsById, items, isLoaded: true }
}

function addSingleTransaction(state, action) {
  if (!action.response) {
    return { ...state }
  }

  const currentTransactionId = action.response.id,
    itemsById = { ...state.itemsById, [action.response.id]: action.response }
  return { ...state, currentTransactionId, itemsById }
}

export default reducer
