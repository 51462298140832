const initialState = {
  toteIDs: [],
  totes: {},
  past_totes: [],
  past_totes_page: 1,
  additional_past_totes_available: true,
  latest_rental_tote: {},
  latest_active_try_on_tote: {},
  newestToteID: undefined,
  loadedLatestTotes: false,
  has_styling_past_totes: false //past_totes 是否有styling的totes
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'API:TOTES:FETCH:SUCCESS': //no longer necessary?
      return arrangeTotes(state, action)
    case 'API:TOTES:FETCH_PAST_TOTES:SUCCESS':
      return setPastTotes(state, action)
    case 'API:TOTES:FETCH_LATEST_RENTAL_PURCHASE:SUCCESS':
      return {
        ...state,
        latest_rental_tote: { ...action.response.data.latest_rental_tote },
        latest_active_try_on_tote: {
          ...action.response.data.latest_active_try_on_tote
        },
        loadedLatestTotes: true
      }
    case 'API:TOTES:PURCHASE_WHOLE_TOTE:STARTED':
      return purchaseWholeToteStarted(state, action)
    case 'API:TOTES:PURCHASE_WHOLE_TOTE:SUCCESS':
      return purchaseWholeToteSuccess(state, action)
    case 'API:TOTES:PURCHASE_WHOLE_TOTE:ERROR':
      return purchaseWholeToteError(state, action)
    case 'API:RATINGS:SUBMIT_TOTE_RATING:SUCCESS':
      return setToteRating(state, action)
    case 'API:TOTE_SWAP:LOCK_TOTE:SUCCESS':
      return lockToteSuccess(state, action)
    case 'API:TOTE_SWAP:REMOVE_PRODUCT:SUCCESS':
      return removeProductSuccess(state, action)
    case 'API:TOTES:FETCH_LATEST:SUCCESS':
      return {
        ...state,
        latest_rental_tote: {
          ...state.latest_rental_tote,
          ...action.response.data.customizeable_tote
        }
      }
    case 'API:TOTES:MARK_TOTE_AS_DELIVERED:SUCCESS':
      if (action.response.data.MarkToteDelivered.tote.rental) {
        return {
          ...state,
          latest_rental_tote: { ...action.response.data.MarkToteDelivered.tote }
        }
      } else {
        return {
          ...state,
          latest_active_try_on_tote: {
            ...action.response.data.MarkToteDelivered.tote
          }
        }
      }
    default:
      return state
  }
}

function removeProductSuccess(state, action) {
  let latest_rental_tote = { ...state.latest_rental_tote }
  let { tote_products } = latest_rental_tote
  let oldToteProductId = action.response.id

  tote_products = tote_products.filter(function(tote_product) {
    return tote_product.id !== oldToteProductId
  })

  latest_rental_tote.tote_products = tote_products

  return {
    ...state,
    latest_rental_tote
  }
}

function setPastTotes(state, action) {
  const additional_past_totes_available =
      action.response.data.totes.length === 5,
    //当return一个totes之后，totes里面个styling 的最新totes，从totes里面去掉
    past_totes = action.response.data.totes.filter(
      item => item.scheduled_pickup !== null
    )
  return {
    ...state,
    past_totes: state.past_totes.concat(past_totes),
    past_totes_page: state.past_totes_page + 1,
    additional_past_totes_available,
    has_styling_past_totes: past_totes.length !== action.response.data.totes
  }
}

function arrangeTotes(state, action) {
  let totes = {}

  const toteIDs = _.map(action.response, tote => tote.id)

  _.map(action.response, tote => {
    totes[tote.id] = tote
  })

  const newestToteID = _.sortBy(toteIDs).pop()

  return { ...state, toteIDs, totes, newestToteID }
}

const purchaseWholeToteStarted = (state, action) => {
  const toteId = action.data.variables.tote.tote_id,
    rentalId = state.latest_rental_tote.id,
    purchaseId = state.latest_active_try_on_tote.id,
    purchasingRental = rentalId && rentalId === toteId,
    purchasingSelect = purchaseId && purchaseId === toteId

  if (purchasingRental) {
    return {
      ...state,
      latest_rental_tote: {
        ...state.latest_rental_tote,
        purchasingWholeTote: true
      }
    }
  } else if (purchasingSelect) {
    return {
      ...state,
      latest_active_try_on_tote: {
        ...state.latest_active_try_on_tote,
        purchasingWholeTote: true
      }
    }
  }
}

const purchaseWholeToteSuccess = (state, action) => {
  const toteId = action.data.variables.tote.tote_id,
    rentalId = state.latest_rental_tote.id,
    purchaseId = state.latest_active_try_on_tote.id,
    purchasingRental = rentalId && rentalId === toteId,
    purchasingSelect = purchaseId && purchaseId === toteId

  if (purchasingRental) {
    return {
      ...state,
      latest_rental_tote: {
        ...state.latest_rental_tote,
        purchasedWholeTote: true
      }
    }
  } else if (purchasingSelect) {
    return {
      ...state,
      latest_active_try_on_tote: {
        ...state.latest_active_try_on_tote,
        purchasedWholeTote: true
      }
    }
  }
}

const lockToteSuccess = (state, action) => {
  const newToteState = action.response.data.LockTote.tote.state
  const newToteShippingStatus =
    action.response.data.LockTote.tote.shipping_status

  return {
    ...state,
    latest_rental_tote: {
      ...state.latest_rental_tote,
      shipping_status: newToteShippingStatus,
      state: newToteState
    }
  }
}

const purchaseWholeToteError = (state, action) => {
  const toteId = action.data.variables.tote.tote_id,
    rentalId = state.latest_rental_tote.id,
    purchaseId = state.latest_active_try_on_tote.id,
    purchasingRental = rentalId && rentalId === toteId,
    purchasingSelect = purchaseId && purchaseId === toteId

  if (purchasingRental) {
    return {
      ...state,
      latest_rental_tote: {
        ...state.latest_rental_tote,
        purchaseWholeToteError: true
      }
    }
  } else if (purchasingSelect) {
    return {
      ...state,
      latest_active_try_on_tote: {
        ...state.latest_active_try_on_tote,
        purchaseWholeToteError: true
      }
    }
  }
}

const setToteRating = (state, action) => {
  const toteId = action.response.data.RateTote.tote_rating.tote_id,
    latestRentalToteId = state.latest_rental_tote.id,
    latestTryOnToteId = state.latest_active_try_on_tote.id

  //look in latest rental, latest purchase, and past totes
  //to update correct tote with new rating
  if (toteId === latestRentalToteId) {
    return {
      ...state,
      latest_rental_tote: {
        ...state.latest_rental_tote,
        tote_rating: action.response.data.RateTote.tote_rating
      }
    }
  } else if (toteId === latestTryOnToteId) {
    return {
      ...state,
      latest_active_try_on_tote: {
        ...state.latest_active_try_on_tote,
        tote_rating: action.response.data.RateTote.tote_rating
      }
    }
  } else {
    let pastToteToUpdate = _.find(state.past_totes, { id: toteId })
    pastToteToUpdate.tote_rating = action.response.data.RateTote.tote_rating
    const past_totes = _.extend(state.past_totes, pastToteToUpdate)
    return {
      ...state,
      past_totes
    }
  }
}

export default reducer
