const initialState = {
  productIds: [],
  scrollTop: 0
}

function reducer(state = initialState, action) {
  const { data } = action
  switch (action.type) {
    case 'API:CUSTOMER:FETCH:SUCCESS':
      return fetchCloset(state, action)
    case 'CLOSET:STORE_SCROLL_TOP':
      return { ...state, scrollTop: data }
    default:
      return state
  }
}

function fetchCloset(state, action) {
  const ids = action.response.customer_products,
    productIds = [...state.productIds, ...ids]
  return { ...state, productIds }
}

export default reducer
